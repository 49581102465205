body, html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;

    & > .c-layout-page {
        flex: 1 0 auto;
    }

    & > footer {
        flex-shrink: 0;
      }
}

.imagecontour {
    padding: 10px;
    border: solid 1px #EFEFEF;
    background-color: #FFFFFF;
}

.effect2 {
    position: relative;
    &:before, &:after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        width: 50%;
        top: 80%;
        max-width:300px;
        background: #777;
        box-shadow: 0 15px 10px #777;
        transform: rotate(-3deg);
    }
    &:after{
        transform: rotate(3deg);
        right: 10px;
        left: auto;
    }
}

#pp_return_url {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.c-shop-order-complete-1 {
    #pp_form {
        margin-bottom: 10px;
        & > input[type="image"] {
            display: block;
            margin: 0 auto;
            &:focus {
                outline: none;
            }
        }
    }
}


